<template>
  <!-- 我的提单  我的题目 -->
  <div class="">
    <div class="box">
      <el-table
        ref="singleTable"
        v-loading="loading"
        :data="tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%"
        @row-click="rowDblclick"
      >
        <el-table-column
          property="order_num"
          align="center"
          label="订单编号"
          width="200"
        >
        </el-table-column>

        <el-table-column
          property="title"
          align="center"
          label="商品名称"
          width="220"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column property="price" align="center" label="单价">
        </el-table-column>

        <el-table-column property="teach_name" align="center" label="老师">
          <template slot-scope="scope">
            <div v-if="scope.row.teach_name">{{ scope.row.teach_name }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          property="pay_type_str"
          align="center"
          label="付款方式"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.pay_type_str">
              {{ scope.row.pay_type_str }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="交易状态">
          <template slot-scope="scope">
            <div
              style="color: #2e89c7"
              v-if="scope.row.status == 1 || scope.row.status == 0"
            >
              待支付
            </div>
            <div style="color: #28b58c" v-if="scope.row.status == 2">
              已支付
            </div>
            <div style="color: red" v-if="scope.row.status == 3">退款中</div>
            <div style="color: #ffdf3e" v-if="scope.row.status == 4">
              已退款
            </div>
            <div style="color: #ffdf3e" v-if="scope.row.status == 5">
              退款被驳回
            </div>
            <!-- <div style="color:#F15153" v-if="scope.row.status==6">退款被驳回</div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <div
              v-if="row.status == 1 || row.status == 0"
              @click="isRowDb = false"
            >
              <el-button
                type="text"
                @click="
                  $handleConfirm(
                    '你确定取消该' + (row.typeStr || '订单') + '吗？',
                    '/xapi/com.order/cancel',
                    { id: row.id },
                    () => {
                      getOrderList(page);
                    },
                    { title: '取消购买' }
                  )
                "
                >取消</el-button
              >
              <el-button
                type="text"
                @click="
                  currentData = row;
                  $refs.orderPayment.visibled(true);
                "
                >支付</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <diy-dialog
      class="order-payment-dialog"
      :bodyStyle="{ 'padding-top': 0 }"
      ref="orderPayment"
      width="500px"
      :showFooter="false"
    >
      <order-sure
        type="base"
        :detail="{ ...currentData, showResult: false }"
        @successPay="successPay"
        :status="true"
        :order_id="currentData.id"
      ></order-sure>
    </diy-dialog>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        :total="total"
        @current-change="getOrderList"
        @size-change="onSizeChange"
        :page-size="pageSize"
        :current-page="page"
      >
      </el-pagination>
    </div>
    <diy-dialog ref="catDialog">
      <el-table :data="[currentData]">
        <el-table-column
          property="user_nickname"
          align="center"
          label="用户"
          width="160"
          >{{ userInfo.nickname }}</el-table-column
        >
        <el-table-column
          property="title"
          align="center"
          label="商品名称"
          width="220"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column property="price" align="center" label="订单金额">
        </el-table-column>

        <el-table-column
          property="teach_name"
          align="center"
          label="老师"
          width="160"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.teach_name">{{ scope.row.teach_name }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          property="pay_type_str"
          align="center"
          label="付款方式"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.pay_type_str">
              {{ scope.row.pay_type_str }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单状态">
          <template slot-scope="scope">
            <div
              style="color: #2e89c7"
              v-if="scope.row.status == 1 || scope.row.status == 0"
            >
              待支付
            </div>
            <div style="color: #28b58c" v-if="scope.row.status == 2">
              已支付
            </div>
            <div style="color: red" v-if="scope.row.status == 3">退款中</div>
            <div style="color: #ffdf3e" v-if="scope.row.status == 4">
              已退款
            </div>
            <div style="color: #ffdf3e" v-if="scope.row.status == 5">
              退款被驳回
            </div>
            <!-- <div style="color:#F15153" v-if="scope.row.status==6">退款被驳回</div> -->
          </template>
        </el-table-column>
        <el-table-column
          property="order_num"
          align="center"
          label="订单编号"
          width="200"
        >
        </el-table-column>
      </el-table>
    </diy-dialog>
  </div>
</template>

<script>
import { orders } from "@/api/me.js";
import OrderSure from "@/views/api/com/orderSure";
export default {
  components: { OrderSure },
  data() {
    //这里存放数据
    return {
      loading: false,
      order_num: "",
      mobile: "",
      price: "",
      page: 1,
      pageSize:10,
      total: 0,
      tableData: [],
      currentData: {},
      isRowDb: true,
    };
  },
  //方法集合
  methods: {
    routeUpdate() {
      this.getOrderList();
    },
    rowDblclick(e) {
      this.currentData = e;
      if (this.isRowDb === true) {
        this.$refs.catDialog.visibled(true);
      }
      this.$nextTick(() => {
        this.isRowDb = true;
      });
    },
    getOrderList(page) {
      this.page = page || 1;
      this.loading = true;
      orders({ page_size: this.pageSize, page: this.page })
        .then(({ data }) => {
          this.tableData = data.data;
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSizeChange(pageSize) {
    this.pageSize = pageSize;
    this.getOrderList();
  },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    successPay() {
      console.log("触发关闭");
      // return
      this.$refs.orderPayment.visibled(false);
      this.getOrderList()
    },
  },
};
</script>
<style  lang='less' scoped>
</style>